import {Units} from './units';
import {CreditoHipotecario} from './creditoHipotecario';

export class PropuestaConstructor {
  constructor(
    public document: number,
    public name: string,
    public phone: number,
    public email: string,
    public proyecto: string,
    public fechaCotizacion: string,
    public expirationDate: string,
    public fechaSeparacion: any,
    public fechaCredito: string,
    public valorInmueble: number,
    public saldoCuotaInicial: number,
    public valorApartar: number,
    public valorCredito: number,
    public numCuotas: number,
    public porSeparacion: number,
    public porCuotaInicial: number,
    public porCredito: number,
    public cuotas: Array<any>,
    public unidades: Array<Units>,
    public type_document: string,
    public id_zoho: number,
    public observation: string,
    public creditoHipotecario: Array<CreditoHipotecario>,
    public subsidio: number,
    public ahorroProgramado: number,
    public cesantias: number,
    public cuotaInicialTotal: number,
    public descuentoComercial: number,
    public totalCalculadoMenosDescuento: number,
    public cuotaAdicionalMensual: number,
    public porcentajeCuotaInicialCalculada: number,
    public gastosEscrituracion: number,
    public formaPagoGastsEscr: Array<any>,
    public diaPago: number,
    public subsidioInicial: number,
    public subsidioCredito: number
  ) {
  }
}
