export const constantes = {
  AGREGAR_CUOTA: 'Agregar cuota',
  DUPLICAR_CUOTA: 'Duplicar cuota',
  ELIMINAR_CUOTA: 'Eliminar cuota',
  INFORMACION_COTIZACION: 'Información de la cotización',
  INMUEBLES_COTIZADOS: 'Información de los inmuebles cotizados',
  PROPUESTA_CONSTRUCTOR: 'Propuesta de pagos por parte del constructor',
  PROPUESTA_CLIENTE: 'Propuesta de pagos por parte del cliente',
  PERIODO: 'Periodo',
  FECHA: 'Fecha de pago',
  DIAS: 'Dias',
  CUOTA: 'Valor de la cuota',
  CUOTAS: 'Cuotas',
  VALOR: 'Valor',
  CONCEPTO: 'Concepto',
  ACCIONES: 'Acciones',
  SEPARACION: 'Separación',
  CREDITO: 'Crédito',
  TOTAL_CONSTRUCTOR: 'Total constructor',
  DIFERENCIA: 'Diferencia',
  TOTAL_CLIENTE : 'Total cliente',
  APLICACION_DIFERENCIA : 'Aplicación de la diferencia',
  VALOR_FUTURO_APLICAR : 'Valor total interés',
  VALOR_DESCUENTO_NED_DIF : 'Valor descuento al negocio según propuesta Cliente',
  VALOR_INTERESES_NED_DIF : 'Valor de los intereses al negocio según propuesta cliente',
  VALOR_TOTAL_PAGAR : 'Valor total a pagar',
  TASA_MENSUAL_DESC : 'Tasa descuento',
  TASA_MENSUAL_FINAN : 'Tasa financiación',
  TASAS_SINCO : 'Estas tasas son las configuradas en Sinco',
  TOTALES_IGUALES : 'Los totales de las propuestas deben ser iguales',
  DESCUENTO_COMERCIAL: 'Descuento Comercial',
  PORCENTAJE_CUOTA_INICIAL: '% cuota inicial',
  GASTOS_ESCRITURACION: 'G. de escrituración aprox',
  DIA_PAGO: 'Dia pago',
  PAGO_INTERES: 'Pago Interés',
  VALOR_CUOTA: 'valorCuota',
  VALOR_CUOTA_PRESENTE: 'valorCuotaPresente',
  VALOR_DESC: 'valorDesc',
  VALOR_INTERESES: 'valorIntereses',
  VALOR_INTERESES_SEGUN_PAGO: 'Valor intereses según pago',
  EXCLUIR: 'excluir',
  INCLUIR: 'incluir',
  VACIO: '',
  CAMPO_REQUERIDO: 'Campo requerido',
  CAMPO_NUMERICO: 'El campo debe ser numérico',
  CAMPO_EMAIL: 'El campo no es una dirección de email valida',
  TASAS_LABEL: 'Tasas',
  VS_PROPUESTAS_LABEL: 'Versus de las dos propuestas',
  DIA: 'Día',
  MES: 'Mes',
  ANIO: 'Año',
  ALERTA_INTERES_APAGADO: 'No se cobrarán los intereses generados por la forma de pago del Cliente',
  CONFIRMA_ACCION: '¿Esta seguro de aplicar esta acción?',
  FECHA_MENOR: 'La fecha de la cuota para el pago de los intereses en la propuesta Cliente no puede ser superior a la fecha de la aplicación de la diferencia',
  FECHA_SEPARACION: 'Fecha separación',
  FORMA_PAGO_INTERESES_PROPUESTA_CLIENTE: 'No se ha definido la forma de pago para los intereses al negocio según propuesta cliente',
  SUBSIDIO: 'Subsidio',
  SUBSIDIO_INICIAL: 'Subsidio Inicial',
  SUBSIDIO_CREDITO: 'Subsidio Crédito'

};
