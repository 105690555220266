export class PropuestaCliente {
  constructor(
    public cuotas: Array<any>,
    public fechaCotizacion: string,
    public fechaCredito: string,
    public valorCuotaInicial: number,
    public valorApartar: number,
    public valorCredito: number,
    public porSeparacion: number,
    public porCuotaInicial: number,
    public porCredito: number,
    public numCuotas: number,
    public cuotaExtInicial: number,
    public cuotaExtCredito: number
  ) {
  }
}
