import {Component, OnInit, EventEmitter, DoCheck} from '@angular/core';
import {Router} from '@angular/router';
import {ApiConinsaService} from '../shared/services/apiConinsa.service';
import {NotificacionesService} from '../shared/services/notificaciones.service';
import {Filtros} from '../shared/models/filtros';
import {Cities} from '../shared/models/cities';
import {Business} from '../shared/models/business';
import {Projects} from '../shared/models/projects';
import {Units} from '../shared/models/units';
import {ApiService} from '../shared/services/api.service';
import {Parametros} from '../shared/models/parametros';

declare var $: any;

@Component({
  selector: 'filter',
  templateUrl: '../views/filter.component.html',
  providers: [ApiConinsaService, NotificacionesService, ApiService]
})

export class FilterComponent implements OnInit, DoCheck {
  public cities: Cities[] = [];
  public business: Business[] = [];
  public projects: Projects[] = [];
  public filtros: Filtros;
  public units: Units[] = [];
  public alert: string;
  public parametros: Parametros[] = [];
  public selectProject: Projects[] = [];

  constructor(
    private _cs: ApiConinsaService,
    private _fs: NotificacionesService,
    private _router: Router,
    private _bs: ApiService,
  ) {
    this.filtros = new Filtros(1, 0, 0, null, 0);
    this.alert = '';
  }

  public ngOnInit() {
    this.getCities();
    this.getBusiness();
    this.cargarParametros();
  }

  public ngDoCheck() {
    const objectFiltros = {
      empresa: this.filtros.empresa,
      ciudad: this.filtros.ciudad,
      megaProyecto: this.filtros.megaproyecto,
      proyecto: this.filtros.proyecto,
      inmueble: this.filtros.inmueble
    };
    sessionStorage.setItem('filtros', JSON.stringify(objectFiltros));
  }

  public getCities() {
    this.units = [];
    this._fs.downdloadAlert('Cargando ciudades');
    this._cs.getCitiesService().subscribe(
      response => {
        this.cities = response;
        this._fs.closeAlert();
      },
      error => {
        this._fs.errorAlert('No se pueden cargar las ciudades, por favor comunicarse con el administrador del sistema');
      },
    );
  }

  public getBusiness() {
    this.units = [];
    this._fs.downdloadAlert('Cargando empresa');
    this._cs.getBusinessService().subscribe(
      response => {
        this.business = response;
        this._fs.closeAlert();
        this.getProjects();
      },
      error => {
        this._fs.errorAlert('No se pueden cargar las empresas, por favor comunicarse con el administrador del sistema');
      },
    );
  }

  public getProjects() {
    this.units = [];
    this.filtros.proyecto = null;
    this._fs.downdloadAlert('Cargando proyectos');
    this._cs.getProjectsService(this.filtros).subscribe(
      response => {
        this.projects = response;
        this._fs.closeAlert();
      },
      error => {
        this._fs.errorAlert('No se pueden cargar los proyectos, por favor comunicarse con el administrador del sistema');
      },
    );
  }

  public getUnits() {
    this.filtros.inmueble = 0;
    if (this.filtros.empresa == 0) {
      this._fs.errorAlert('Debe seleccionar una empresa');
      return false;
    }
    if (this.filtros.proyecto) {
      this._fs.downdloadAlert('Cargando Unidades');
      this._cs.getUnitsService(this.filtros).subscribe(
        response => {
          this.units = response;
          this._fs.closeAlert();
          if (this.units.length < 1) {
            this._fs.warningAlert('Este proyecto no posee unidades principales disponibles');
          }
        },
        error => {
          this._fs.errorAlert('No se pueden cargar las unidades, por favor comunicarse con el administrador del sistema');
        },
      );
    } else {
      this.units = [];
    }
  }

  public cargarParametros() {
    this._bs.getParameters().subscribe(
      response => {
        sessionStorage.setItem('parametros', JSON.stringify(response));
      },
      error => {
        console.log('No se pueden cargar los parametros, por favor comunicarse con el administrador del sistema');
      }
    );

    this._bs.getActiveYearCredit().subscribe(
      response => {
        const items = response;
        sessionStorage.setItem('creditParameters', JSON.stringify(items.data));
      },
      error => {
        console.log('No se pueden cargar los parametros del crédito hipotecario, por favor comunicarse con el administrador del sistema');
      }
    );
  }

  public calculate(): any {
    let selectUnit: Units[];
    this.selectProject = this.projects.filter(
      project => project.idProyecto === this.filtros.proyecto
    );
    selectUnit = this.units.filter(
      unit => unit.idUnidad === this.filtros.inmueble
    );
    console.log(selectUnit);
    this.selectProject[0].cuotaInicial = 100 - this.selectProject[0].financia;
    this._cs.getInfoProjectService(this.selectProject[0].CBPproyectoABR).subscribe(response => {
      if (response && response.data.buildingQuery.entities.length > 0) {
        this.selectProject[0].sucursal = response.data.buildingQuery.entities[0].Sucursal ? response.data.buildingQuery.entities[0].Sucursal : 'Colombia';
      }
      sessionStorage.setItem('selectProject', JSON.stringify(this.selectProject));
      sessionStorage.setItem('note', response.data.buildingQuery.entities[0].Note ? response.data.buildingQuery.entities[0].Note : '');
    });
    if (this.filtros.inmueble !== 0) {
      this.alert = '';
    }
  }

  public validateFilter(): any {
    if (Number(this.filtros.inmueble) === 0) {
      this.alert = 'Para continuar debe seleccionar un inmueble';
      return false;
    }
    if (!this.selectProject[0] || !this.selectProject[0].sucursal) {
      this._fs.errorAlert('El proyecto no tiene asignada una sucursal');
      return false;
    }

    const url = this._router.createUrlTree(['/app/calculator']);
    window.open(url.toString(), '_blank');
  }
}
